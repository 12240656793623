import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import App from './component/App';
import { client } from './client';

createRoot(document.getElementById('app')!).render(
    <ApolloProvider client={client}>
        <Router>
            <App />
        </Router>
    </ApolloProvider>,
);

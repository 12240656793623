import { gql } from '@apollo/client';

const guestCheckinQuery = gql`
    query guestCheckin($id: Int!) {
        booking(id: $id) {
            id
            reference
            detailsRecorded
            checkin
            checkout
            checkinTime
            checkoutTime
            guests {
                id
                firstName
                lastName
                sex
                nationality
                postcode
                country
                address
                birthDate
                birthPlace
                idDocumentType
                idDocumentId
                hasUploadedIdDocument
                hasAdditionalDocument
            }
            guestInstructionsUrl
        }
    }
`;

export default guestCheckinQuery;

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const baseUrl = import.meta.env.VITE__API_URL || '/api';

const httpLink = createUploadLink({
    uri: `${baseUrl}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    // eslint-disable-next-line no-restricted-globals
    const params = new URLSearchParams(location.search);
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: `bearer ${params.get('key')}`,
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
        },
    },
});

import { Guest } from '../../types';

const GuestTextField = ({
    name,
    label,
    value,
    placeholder,
    onChange,
}: {
    name: keyof Guest;
    label: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}) => {
    return (
        <div className="row mb-3 form-group">
            <div className="col-md-4 col-form-label">
                <label htmlFor={name}>{label}</label>
            </div>
            <div className="col-md-8">
                <input
                    type="text"
                    className="form-control"
                    id={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={event => {
                        onChange(event.target.value);
                    }}
                />
            </div>
        </div>
    );
};

export default GuestTextField;

import { gql, useQuery } from '@apollo/client';

const NationalityField = ({
    value,
    index,
    onChange,
}: {
    value: string;
    index: number;
    onChange: (value: string) => void;
}) => {
    const {
        loading,
        data: { nationalityList } = {
            nationalityList: [],
        },
    } = useQuery<{ nationalityList: { code: string; name: string }[] }>(gql`
        {
            nationalityList {
                code
                name
            }
        }
    `);

    if (loading) {
        return null;
    }

    return (
        <div className="row mb-3 form-group">
            <div className="col-md-4 col-form-label">
                <label htmlFor="sex">Nationality</label>
            </div>
            <div className="col-md-8">
                <select
                    value={value}
                    onChange={event => {
                        onChange(event.target.value);
                    }}
                    className="form-control"
                >
                    <option>{''}</option>
                    {nationalityList
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(({ code, name }) => (
                            <option value={code} key={code}>
                                {name}
                            </option>
                        ))}
                </select>
            </div>
        </div>
    );
};

export default NationalityField;

import { GuestCheckinResult } from '../types';

const Home = ({ booking }: { booking: GuestCheckinResult }) => {
    return (
        <div>
            <div>
                <strong>Checkin:</strong> {booking.checkin} {booking.checkinTime}
            </div>
            <div>
                <strong>Checkout:</strong> {booking.checkout} {booking.checkoutTime}
            </div>
            <div>
                <p>
                    <strong>Host contact (WhatsApp / Phone):</strong> <a href="tel:+447951518319">+44 7951 518319</a>
                </p>
            </div>
            {booking.guestInstructionsUrl && booking.detailsRecorded && (
                <div className="mt-4">
                    <p>
                        You can now download your instructions on how to access the apartment and save it to your
                        devices or print it in case you have it even if you happen to lose internet access.
                    </p>

                    <a
                        className="btn btn-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={booking.guestInstructionsUrl}
                    >
                        Download instructions
                    </a>
                </div>
            )}
            {booking.guestInstructionsUrl && !booking.detailsRecorded && (
                <div className="mt-4">
                    Your instructions on how to access the apartment will appear here once the above steps are complete.
                </div>
            )}
            <div className="mt-4">
                <h3>Arriving from the airport</h3>
                <p>
                    If you are arriving from the Airport, the easiest way to get there is to get a taxi (around 7000 HUF
                    / 22 EUR).
                </p>
                <p>
                    {' '}
                    An alternative way is to get the{' '}
                    <a href="https://bkk.hu/en/airport-shuttle/" target="_blank" rel="noreferrer">
                        100E bus
                    </a>{' '}
                    (900 HUF) to Astoria, and walk 10 minutes from there.
                </p>
                <p>
                    Google Maps is also a great source for alternative routes and is highly recommend in general, you
                    can{' '}
                    <a
                        href="https://support.google.com/maps/answer/6291838?hl=en-GB&co=GENIE.Platform%3DiOS"
                        target="_blank"
                        rel="noreferrer"
                    >
                        download the city map for offline use
                    </a>
                    .
                </p>
            </div>
            <div className="mt-4">
                <h3>Taxi</h3>
                <p>
                    The recommended taxi service is Főtaxi, they are professional and convenient and have a{' '}
                    <a href="https://fotaxi.hu/en/passengers/taxi-application/" target="_blank" rel="noreferrer">
                        mobile app
                    </a>{' '}
                    to make getting around convenient.
                </p>
                <a
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://fotaxi.hu/en/passengers/taxi-application/"
                >
                    Get the Főtaxi app
                </a>
            </div>
            {/*
                <div className="mt-4">
                    <h3>Preordering Hungarian wine</h3>
                    <p>Preorder Hungarian wine and have it waiting for you when you arrive.</p>
                    <a className="btn btn-primary" target="_blank" rel="noopener noreferrer" href="">
                        Browse wine selection
                    </a>
                </div>
            */}
            <div className="mt-4">
                <h3>Ordering food</h3>
                <p>Feeling like staying in for the day? You can use Wolt to order food, groceries or even drinks.</p>
                <a
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://wolt.com/en/hun/budapest"
                >
                    Go to Wolt
                </a>
            </div>
            <div className="mt-4">
                <h3>Taking a bus tour</h3>
                <p>Take a tour of the city with 'hop on and hop off' buses.</p>
                <a
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.bigbustours.com/en/budapest/budapest-bus-tours"
                >
                    Buy tickets for Budapest Bus Tours
                </a>
            </div>
            <div className="mt-4">
                <h3>Changing checkin / checkout times</h3>
                <p>
                    If you would like to either checkin earlier or checkout later, please let me know in advance and I
                    will try to arrange it.
                </p>
                <p>There is no extra charge for this if I am able to arrange it.</p>
                <p>
                    I can usually arrange it, if it doesn't conflict with cleaning schedule or other maintenance work.
                </p>
                <p>If I am unable to alter the times, I will suggest potential alternatives.</p>
            </div>
        </div>
    );
};

export default Home;

import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import 'bootstrap';
import { useHistory } from 'react-router-dom';
import { Guest, GuestCheckinResult } from '../types';
import CountryField from './common/CountryField';
import GuestTextField from './common/GuestTextField';
import NationalityField from './common/NationalityField';
import guestCheckinQuery from './gql/guestCheckinQuery';

/* eslint react/no-array-index-key: 0, react/jsx-curly-brace-presence: 0, max-lines: ["error", 650] */

// eslint-disable-next-line no-restricted-globals
const params = new URLSearchParams(location.search);

const performSave = debounce((guests: GuestCheckinResult['guests']) => {
    (async () => {
        const id = (params.get('key') || '').split(';')[1];
        await fetch(`/api/checkin/${id}?key=${params.get('key')}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                guests: guests.map(guest => {
                    const birthDateParts = guest.birthDate.split('-');
                    const birthDate =
                        birthDateParts[0] || birthDateParts[1] || birthDateParts[2]
                            ? `${birthDateParts[0]}-${birthDateParts[1].padStart(2, '0')}-${birthDateParts[2].padStart(
                                  2,
                                  '0',
                              )}`
                            : '';
                    return {
                        ...guest,
                        birthDate,
                    };
                }),
            }),
        });
    })().catch(error => {
        throw error;
    });
}, 1000);

const isGuestValid = ({
    firstName,
    lastName,
    sex,
    nationality,
    country,
    postcode,
    address,
    birthDate,
    birthPlace,
    idDocumentType,
    idDocumentId,
    hasUploadedIdDocument,
}: Guest) => {
    if (!firstName) {
        return false;
    }
    if (!lastName) {
        return false;
    }
    if (!sex) {
        return false;
    }
    if (!nationality) {
        return false;
    }
    if (!country) {
        return false;
    }
    if (!postcode) {
        return false;
    }
    if (!address) {
        return false;
    }
    if (birthDate.split('-').filter(i => !!i).length !== 3) {
        return false;
    }
    if (!birthPlace) {
        return false;
    }
    if (!idDocumentType) {
        return false;
    }
    if (!idDocumentId) {
        return false;
    }
    if (!hasUploadedIdDocument) {
        return false;
    }
    return true;
};

const idDocumentUploadMutation = gql`
    mutation uploadGuestIdDocument($id: Int!, $file: Upload!) {
        uploadGuestIdDocument(id: $id, file: $file)
    }
`;

const GuestForm = ({
    index,
    guest: {
        id,
        firstName,
        lastName,
        sex,
        nationality,
        country,
        postcode,
        address,
        birthDate,
        birthPlace,
        idDocumentType,
        idDocumentId,
        hasUploadedIdDocument,
    },
    setField,
}: {
    index: number;
    guest: Guest;
    setField: (data: { index: number; name: keyof Guest; value: string | boolean }) => void;
}) => {
    const [reUploadIdDocument, setReUploadIdDocument] = useState(false);
    const [uploadIdDocument] = useMutation(idDocumentUploadMutation);
    const [isUploading, setIsUploading] = useState(false);
    const birthDateParts = birthDate.split('-');
    const birthYear = birthDateParts[0] || '';
    const birthMonth = birthDateParts[1] || '';
    const birthDay = birthDateParts[2] || '';

    return (
        <div className="row mb-3" key={index}>
            <div className="col-md-12">
                <GuestTextField
                    name="firstName"
                    value={firstName}
                    label="First name"
                    onChange={value =>
                        setField({
                            index,
                            name: 'firstName',
                            value,
                        })
                    }
                />

                <GuestTextField
                    name="lastName"
                    value={lastName}
                    label="Last name"
                    onChange={value =>
                        setField({
                            index,
                            name: 'lastName',
                            value,
                        })
                    }
                />

                <div className="row mb-3 form-group">
                    <div className="col-md-4 col-form-label">
                        <label htmlFor="sex">Sex</label>
                    </div>
                    <div className="col-md-8">
                        <select
                            className="form-control"
                            id="sex"
                            value={sex}
                            onChange={event => {
                                setField({
                                    index,
                                    name: 'sex',
                                    value: event.target.value,
                                });
                            }}
                        >
                            <option value="">{''}</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>
                </div>

                <NationalityField
                    value={nationality}
                    index={index}
                    onChange={value =>
                        setField({
                            index,
                            name: 'nationality',
                            value,
                        })
                    }
                />

                <CountryField
                    value={country}
                    onChange={value =>
                        setField({
                            index,
                            name: 'country',
                            value,
                        })
                    }
                />

                <GuestTextField
                    name="postcode"
                    value={postcode}
                    label="Post code (zip code)"
                    onChange={value =>
                        setField({
                            index,
                            name: 'postcode',
                            value,
                        })
                    }
                />

                <GuestTextField
                    name="address"
                    value={address}
                    label="Address"
                    onChange={value =>
                        setField({
                            index,
                            name: 'address',
                            value,
                        })
                    }
                />

                <div className="row mb-3 form-group">
                    <div className="col-md-4 col-form-label">Birth date</div>
                    <div className="col-md-2">
                        <input
                            type="text"
                            className="form-control"
                            min="1"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            id="birthDay"
                            placeholder="Day"
                            value={birthDay}
                            onChange={event => {
                                setField({
                                    index,
                                    name: 'birthDate',
                                    value: `${birthYear}-${birthMonth}-${event.target.value}`,
                                });
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <input
                            type="text"
                            className="form-control"
                            min="1"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            id="birthMonth"
                            placeholder="Month"
                            value={birthMonth}
                            onChange={event => {
                                setField({
                                    index,
                                    name: 'birthDate',
                                    value: `${birthYear}-${event.target.value}-${birthDay}`,
                                });
                            }}
                        />
                    </div>
                    <div className="col-md-2">
                        <input
                            type="text"
                            className="form-control"
                            min="1900"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            id="birthYear"
                            placeholder="Year"
                            value={birthYear}
                            onChange={event => {
                                setField({
                                    index,
                                    name: 'birthDate',
                                    value: `${event.target.value}-${birthMonth}-${birthDay}`,
                                });
                            }}
                        />
                    </div>
                </div>

                <GuestTextField
                    name="birthPlace"
                    value={birthPlace}
                    label="Place of birth"
                    onChange={value =>
                        setField({
                            index,
                            name: 'birthPlace',
                            value,
                        })
                    }
                />

                <div className="row mb-3 form-group">
                    <div className="col-md-4 col-form-label">
                        <label htmlFor="idDocumentType">ID document type</label>
                    </div>
                    <div className="col-md-8">
                        <select
                            className="form-control"
                            id="idDocumentType"
                            value={idDocumentType}
                            onChange={event => {
                                setField({
                                    index,
                                    name: 'idDocumentType',
                                    value: event.target.value,
                                });
                            }}
                        >
                            <option value="">{''}</option>
                            <option value="passport">Passport</option>
                            <option value="id">ID card (EU only)</option>
                            {/*
                            <option value="drivers-license">Driver's license</option>
                            */}
                        </select>
                    </div>
                </div>

                <GuestTextField
                    name="idDocumentId"
                    value={idDocumentId}
                    label="ID document number"
                    onChange={value =>
                        setField({
                            index,
                            name: 'idDocumentId',
                            value,
                        })
                    }
                />
                {isUploading && <div className="row mb-3 form-group">Uploading document...</div>}
                {!isUploading && (
                    <>
                        <div className="row mb-3 form-group">
                            <div className="col-md-4 col-form-label">
                                <label htmlFor="idDocumentFile">ID document</label>
                            </div>
                            <div className="col-md-8">
                                {hasUploadedIdDocument && (
                                    <div className="col-form-label">
                                        Uploaded{' '}
                                        <button
                                            onClick={event => {
                                                event.preventDefault();
                                                setReUploadIdDocument(true);
                                            }}
                                            className="btn btn-secondary btn-lg btn-block"
                                        >
                                            Re-upload
                                        </button>
                                    </div>
                                )}
                                {(!hasUploadedIdDocument || reUploadIdDocument) && (
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="idDocumentFile"
                                        placeholder={'Upload ID document'}
                                        onChange={event => {
                                            if (event.target.validity.valid) {
                                                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                                                const file = (event.target.files as any)[0];
                                                setIsUploading(true);
                                                uploadIdDocument({ variables: { id, file } })
                                                    .then(() => {
                                                        setField({
                                                            index,
                                                            name: 'hasUploadedIdDocument',
                                                            value: true,
                                                        });
                                                    })
                                                    .catch(error => {
                                                        throw error;
                                                    })
                                                    .finally(() => {
                                                        setIsUploading(false);
                                                        setReUploadIdDocument(false);
                                                    });
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const submitGuestDetailsMutation = gql`
    mutation submitGuestDetails($id: Int!) {
        submitGuestDetails(id: $id)
    }
`;

const id = +(params.get('key') || '').split(';')[1];

const CheckinForm = ({ data }: { data: GuestCheckinResult }) => {
    const [guests, setGuests] = useState(data.guests);
    const setField = useCallback(
        ({ index, name, value }: { index: number; name: keyof Guest; value: string | boolean }) => {
            const newList = JSON.parse(JSON.stringify(guests));
            newList[index][name] = value;
            setGuests(newList);
            performSave(newList);
        },
        [setGuests, guests],
    );
    const [submitGuestDetails] = useMutation(submitGuestDetailsMutation, {
        refetchQueries: [{ query: guestCheckinQuery, variables: { id } }],
    });

    const history = useHistory();

    const areGuestsValid = guests.map(isGuestValid).reduce((a, b) => a && b);

    const guestOpenByDefault = data.guests.map(isGuestValid);

    return (
        <>
            <div className="col-md-12 text-left">
                <p className="lead">
                    Please fill out this form for each guest in your group. Your progress is automatically saved, you
                    can enter the information partially and come back later to finish.
                </p>
                <p>
                    {`

                    The information entered is used to comply with regulation 32/2010. (XII. 31.)
                    issued by the council of Budapest District VII.,
                    we respect your data and comply with GDPR.
                    `}
                </p>
                <p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://net.jogtar.hu/rendelet?council=erzsebetvaros&docid=A1000032.07R"
                    >
                        {'https://net.jogtar.hu/rendelet?council=erzsebetvaros&docid=A1000032.07R'}
                    </a>
                </p>
            </div>
            <form
                className="needs-validation pb-5"
                noValidate
                onSubmit={e => {
                    e.preventDefault();

                    if (!areGuestsValid) {
                        return;
                    }

                    submitGuestDetails({
                        variables: { id },
                    })
                        .then(() => {
                            history.push('/');
                        })
                        .catch(error => {
                            throw error;
                        });
                }}
            >
                <div className="accordion" id="guest-list">
                    {guests.map((guest, index) => (
                        <div key={index} className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className={`accordion-button${isGuestValid(guest) ? '' : ' text-danger'}`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#guest-${index}`}
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    {guest.firstName && guest.lastName
                                        ? `${guest.firstName} ${guest.lastName}`
                                        : `Guest
                                    ${index + 1}`}
                                </button>
                            </h2>
                            <div
                                id={`guest-${index}`}
                                className={`accordion-collapse collapse${guestOpenByDefault[index] ? '' : ' show'}`}
                                aria-labelledby="headingOne"
                                /* data-bs-parent="#guest-list" */
                            >
                                <div className="accordion-body">
                                    <GuestForm index={index} guest={guest} setField={setField} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <hr className="mb-4" />
                {!areGuestsValid && <p>Please enter the details for all guests before proceeding.</p>}
                <button disabled={!areGuestsValid} className="btn btn-primary btn-lg btn-block" type="submit">
                    Finalize guest details
                </button>
            </form>
        </>
    );
};

export default CheckinForm;
